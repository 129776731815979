import { db, auth } from "../firebase"

async function createStudentAccount(email, password, userData) {
  let user = await auth.createUserWithEmailAndPassword(email, password)
  let user_id = user.user.uid
  await db
    .collection("users")
    .doc(user_id)
    .set(userData)
    .then(() => {})
    .catch(error => {
      // console.log(error)
      throw Error("Failed second step")
    })
  return user_id
}

export default createStudentAccount
