import Chip from "@material-ui/core/Chip"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React from "react"
import { Col } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}))

const skills = [
  {
    skill: "Art",
  },
  { skill: "Graphic Design" },
  { skill: "Website Development" },
  { skill: "Music" },
  { skill: "Mobile Apps" },
  { skill: "Microsoft Powerpoint" },
  { skill: "Microsoft Excel" },
  { skill: "Microsoft Word" },
  { skill: "Chemistry" },
  { skill: "Biology" },
  { skill: "Writing" },
  { skill: "Photography" },
  { skill: "Videography" },
  { skill: "Geometry" },
  { skill: "Algebra" },
  { skill: "Game Design" },
  { skill: "Google Suite" },
  { skill: "Research" },
  { skill: "Communication" },
]

function SkillSelect(props) {
  const classes = useStyles()
  let prompt = "Start typing..."
  if (props.skills.length === 3) {
    prompt = "Max Number of Skills Reached"
  }
  const checkSkills = value => {
    if (
      JSON.stringify(props.skills) !== JSON.stringify(value) &&
      value.length < 4
    ) {
      props.setSkills(value)
    }
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        style={{ textAlign: "center" }}
        multiple
        onChange={(event, value) => checkSkills(value)}
        defaultValue={props.skills}
        id="tags-standard"
        options={skills.map(option => option.skill)}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map(
            (option, index) =>
              index < 3 && (
                <>
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                </>
              )
          )
        }
        renderInput={params => (
          <Col
            md={12}
            style={{
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
              marginTop: "25px",
              marginBottom: "50px"
            }}
          >
            <TextField
              {...params}
              variant="outlined"
              label="Enter 3 Skills"
              placeholder={prompt}
            />
          </Col>
        )}
      />
      {props.skills.map((value, index) => {
        const centerCol = {
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          marginTop: "25px",
        }
        return (
          <Col md={12} style={centerCol}>
            <TextField
              id="outlined-basic"
              label={props.titles[index]}
              variant="outlined"
              error={false}
              type="text"
              onChange={props.onChange}
              name={props.names[index]}
              value={props.values[index]}
              required
            />
          </Col>
        )
      })}
    </div>
  )
}

export default SkillSelect
