import React from "react"
import Button from "@material-ui/core/Button"
import { Container, Row } from "react-bootstrap"
import { auth } from "../firebase"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import SkillSelect from "../components/utils/SkillSelect"
import TitleLine from "../components/utils/TitleLine"
import createStudentAccount from "../scripts/createStudentAccount"
import VerifyEmail from "../components/onboarding/VerifyEmail"
import "../styles/SignUpPage.css"
import InputCreator from "../components/styledInputs/InputCreator.js"
import Dropdown from "../components/styledInputs/Dropdown.js"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import dots from "../images/bgs/dots.svg"

class StudentAccount extends React.Component {
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }
  constructor(props) {
    super(props)
    this.state = {
      School: "",
      schoolAddress: "",
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      grade: "9th",
      authenticated: false,
      clicked: false,
      modalText: "Registering....",
      onFirstPage: true,
      skills: [],
      experiences: ["", "", ""],
      showModal: false,
      verifyEmail: false,
      studentConsent: false,
      regularInput2: [],
      placeholders: [],
      seriesOne: {},
    }
    this.back = this.back.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputChange2 = this.handleInputChange2.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.setSkills = this.setSkills.bind(this)
    this.createAccount = this.createAccount.bind(this)
    this.signUpStudent = this.signUpStudent.bind(this)
    this.setAddress = this.setAddress.bind(this)
  }

  goToNextPage(reset) {
    const {
      FirstName,
      LastName,
      Email,
      School,
      Password,
      ConfirmPassword,
    } = this.state.seriesOne

    if (
      Object.keys(this.state.seriesOne).length === 7 &&
      FirstName !== "" &&
      LastName !== "" &&
      Email !== "" &&
      School !== ""
    ) {
      if (Password === ConfirmPassword && Password.length >= 6) {
        reset("Success!")
        this.setState({ onFirstPage: false })
      } else {
        reset("Please make sure your passwords match")
        //alert("Please make sure your passwords match")
      }
    } else {
      reset("Please make sure all input fields are filled in")
      //alert("Please make sure all input fields are filled in")
    }
  }

  setSkills(value) {
    this.setState({ skills: value })
    let placeholders = []
    let inputs = []

    for (let i = 0; i < value.length; i++) {
      let skill = value[i]
      placeholders.push(
        "Briefly describe any experience you have had with " + skill
      )
      inputs.push("Experience for " + skill)
    }
    this.setState({ regularInput2: inputs, placeholders: placeholders })
  }

  setAddress(val) {
    let newseries = this.state.seriesOne
    if (val) {
      newseries.School = val.terms[0].value
      newseries.placeID = val.place_id
    } else {
      newseries.School = ""
      newseries.placeID = ""
    }
    this.setState({
      seriesOne: newseries,
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  createAccount(reset) {
    const {
      FirstName,
      LastName,
      Email,
      Password,
      School,
    } = this.state.seriesOne
    let userData = {
      firstName: FirstName,
      lastName: LastName,
      email: Email,
      userType: "student",
      school: School,
      grade: this.state.grade,
      skills: this.state.skills,
      experiences: this.state.experiences,
      pendingProjects: [],
      activeProjects: [],
      reviewProjects: [],
      notifyOnProjectPost: true,
      address: this.state.schoolAddress,
      joinedSlack: false,
      // city: this.state.city,
      // state: this.state.state,
      // country: this.state.country,
      // schoolAddress: this.state.schoolAddress,
    }
    createStudentAccount(Email, Password, userData)
      .then(() => {
        reset("Success!")
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq(
              "track",
              `Volunteer Registration Start`,
              { uid: auth.currentUser.uid }
            )

            window.fbq(
              "trackCustom",
              "Volunteer Registration Start Custom",
              { uid: auth.currentUser.uid }
            )
          }
        }
        this.setState({
          verifyEmail: true,
        })
      })
      .catch(error => {
        console.log(error)
        //TODO ERROR HANDLE
        if (error.code === "auth/email-already-in-use") {
          reset("This email is already in use")
          //alert("This email is already in use")
        } else if (error.code === "auth/invalid-email") {
          reset("Please enter a valid email")
          //alert("Please enter a valid email")
        } else if (error.code === "auth/weak-password") {
          reset("Make sure your passwords match and are longer than 6 characters"
          )
          // alert(
          // "Make sure your passwords match and are longer than 6 characters"
          // )
        }
      })
  }

  signUpStudent(reset) {
    if (
      !this.state.skills.includes("") &&
      !this.state.experiences.includes("") &&
      this.state.skills.length === 3 && this.state.experiences.length === 3
    ) {
      this.createAccount(reset)
    } else {
      if (this.state.skills.length !== 3) {
        reset("Please enter three skills")
      } else {
        if (this.state.experiences.length !== 3) {
          reset("Please enter 3 experiences")
        } else {
          reset("An error occured. Try again?")
        }
      }
    }
  }

  handleForm = event => {
    let tmp = this.state.seriesOne
    tmp[event.target.name] = event.target.value
    this.setState({ seriesOne: tmp })
  }

  handleInputChange2(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    const statevar = name.toString().substring(0, name.length - 1)
    var values = this.state[statevar]
    values[parseInt(name.toString().charAt(name.length - 1))] = value
    this.setState({
      [statevar]: values,
    })
  }

  back() {
    this.setState({ onFirstPage: true })
  }

  render() {
    const regularInput = [
      "First Name",
      "Last Name",
      "Email",
      "Password",
      "Confirm Password",
      "School",
    ]
    const inputNames = [
      "FirstName",
      "LastName",
      "Email",
      "Password",
      "ConfirmPassword",
      "School",
    ]
    const inputType = [
      "text",
      "text",
      "email",
      "password",
      "password",
      "school",
    ]
    const grades = ["9th", "10th", "11th", "12th"]

    const inputNames2 = ["experiences0", "experiences1", "experiences2"]
    if (!this.state.verifyEmail) {
      if (this.state.onFirstPage) {
        return (
          <Container className="topSpacing">
            <TitleLine
              title="Create A Student Account"
              description="Create your student account to begin volunteering"
            />
            <Row className="justify-content-center center sidesPadding">
              <i>
                By creating an account you acknowledge that you are over the age
                of 14 and have parent approval.
              </i>
            </Row>
            <Row className="justify-content-center">
              <InputCreator
                titles={regularInput}
                errors={[false, false, false, false, false, false]}
                types={inputType}
                names={inputNames}
                struct={[2, 1, 1, 1, 1]}
                callback={this.handleForm}
                schoolCallback={this.setAddress}
                values={this.state.seriesOne}
              />
            </Row>
            <Row className="justify-content-center">
              <Dropdown
                value={this.state.grade}
                handleChange={this.handleInputChange}
                options={grades}
                name="grade"
                title="Grade"
              />
            </Row>
            <Row
              className="justify-content-center sidesPadding"
              style={{ marginTop: "20px" }}
            >
              <NewAsyncButton
                buttonTexts={[
                  "Next",
                  "Saving...",
                  "Error. Try Again?",
                  "Success!",
                ]}
                buttonVariants={["primary", "primary", "danger", "success"]}
                buttonColor={["", "", "", ""]}
                callback={this.goToNextPage}
              />
            </Row>
          </Container>
        )
      } else {
        return (
          <Container className="topSpacing">
            <TitleLine
              title="One More Step .... "
              description="Specify 3 skills and the experience you have with each skill (Note : The autocomplete fields are only suggestions). This will help teachers when deciding between students to work with for their projects.
              "
            />
            <Row
              className="sidesPadding justify-content-center"
              style={{ marginBottom: "25px", marginTop: "50px" }}
            >
              <SkillSelect
                setSkills={this.setSkills}
                skills={this.state.skills}
                onChange={this.handleInputChange2}
                names={inputNames2}
                values={this.state.experiences}
                titles={this.state.regularInput2}
              />
            </Row>

            <Row className="justify-content-center sidesPadding">
              <Button
                variant="outline-success"
                className="buttonTop"
                onClick={this.back}
              >
                Back
              </Button>
              <NewAsyncButton
                buttonTexts={[
                  "Register",
                  "Creating Account...",
                  "Error Creating Account. Try Again?",
                  "Successfully Registered!",
                ]}
                buttonVariants={["primary", "primary", "danger", "success"]}
                buttonColor={["", "", "", ""]}
                callback={this.signUpStudent}
              />
            </Row>
          </Container>
        )
      }
    } else {
      return (
        <VerifyEmail
          email={this.state.seriesOne.Email}
          password={this.state.seriesOne.Password}
          navigate="/JoinSlack/"
        />
      )
    }
  }
}

const CreateStudentAccountPage = () => (
  <Layout image={dots} full>
    <SEO title="Create Student Account" />
    <StudentAccount />
  </Layout>
)
export default CreateStudentAccountPage
